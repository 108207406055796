import { Flex, Box } from "@chakra-ui/react";
import { NavList } from "@/components/Nav";
import { INavLink } from "./Nav.type";
import { asideW, asideBg } from "@/theme/layerStyles";

export interface INavside {
  links: INavLink[];
}

export const Navside = ({ links }: INavside) => {
  return (
    // pl & bg are crucial to the Portal layout!
    <Flex
      as="nav"
      zIndex={1}
      position="relative"
      display={{ base: "none", lg: "flex" }}
      direction="column"
      flexShrink={0}
      pl="insetLg"
      w={{ lg: asideW }}
      bg={{ lg: asideBg }}
    >
      <Box layerStyle="underNav">
        <Flex
          align="flex-start"
          direction="column"
          zIndex={1}
          borderLeft="1px solid"
          borderColor="blackAlpha.200"
        >
          <NavList links={links} spacing={1} w="full" ml="-1px"></NavList>
        </Flex>
      </Box>
    </Flex>
  );
};
