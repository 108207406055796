import Link from "next/link";
import { useRouter } from "next/router";
import { Box, StackProps } from "@chakra-ui/react";
import { NavListText } from "@/components/Nav";
import { INavLink } from "./Nav.type";

interface INavside extends StackProps {
  link: INavLink;
}

export const NavListItem = ({ link }: INavside) => {
  const router = useRouter();
  // console.log("router", router);
  const path = router?.pathname;

  const renderActiveLink = (path: any): boolean => {
    if (link.exact && path.startsWith("/todos")) {
      return true;
    }
    if (link.exact && path === link.href) {
      return true;
    }
    if (!link.exact && path.startsWith(link.href)) {
      return true;
    }
    return false;
  };

  return (
    <Link passHref href={link.href}>
      {/* avoid forwardRef bullshit */}
      <Box as="a" display="block" color="transparent">
        <NavListText as="div" isActive={renderActiveLink(path)}>
          {link.name}
        </NavListText>
      </Box>
    </Link>
  );
};
