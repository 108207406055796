import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Flex,
  StackDivider,
} from "@chakra-ui/react";
import { NavListText, NavsideLogout, NavList } from "@/components/Nav";
import LogoSymbol from "@/components/LogoSymbol";
import { INavLink } from "./Nav.type";

// import NavbarSideLinkText from "@/components/NavbarSideLinkText";
// import Logo from "@/components/Logo";
// import config from "@/config";

export interface INavsideDrawer {
  links: INavLink[];
  isOpen: boolean;
  onMobileMenuClose: () => void;
  // onLogout: () => void;
}

export const NavsideDrawer = ({
  links,
  isOpen,
  onMobileMenuClose,
}: INavsideDrawer) => {
  return (
    <Drawer placement="left" isOpen={isOpen} onClose={onMobileMenuClose}>
      <DrawerOverlay bg="blackAlpha.200" />
      <DrawerContent pos="relative" bg="transparent" h="full" maxW="75%">
        {/* retain this as quick design history */}
        <Flex
          w="full"
          minHeight="65px"
          justify="flex-start"
          align="center"
          px="w4"
          bg="white"
          color="peach.500"
          borderBottom="1px solid"
          borderColor="blackAlpha.100"
        >
          <LogoSymbol />
        </Flex>
        {/* <Box layerStyle="navMinH" position="absolute" top={0} left={2}>
          <Flex
            h={{ base: "48px" }}
            align="center"
            opacity={isOpen ? 1 : 0}
            transition="opacity 400ms ease 1s"
          >
            <Fade in={isOpen}>
              <DrawerCloseButton
                position="relative"
                top={2}
                right={0}
                h="3em"
                w="3em"
                fontSize="1rem"
                mr={-3}
                _hover={{ bg: "white" }}
                _focus={{ boxShadow: "none" }}
                _active={{ bg: "thiel.50", outline: "none" }}
              />
            </Fade>
          </Flex>
        </Box> */}

        <NavList
          links={links}
          pt={2}
          bg="white"
          height="full"
          alignItems="flex-start"
          spacing={2}
          divider={<StackDivider borderColor="blackAlpha.100" />}
        >
          <NavsideLogout>
            <NavListText as="div" isReduced>
              Log out
            </NavListText>
          </NavsideLogout>
        </NavList>
      </DrawerContent>
    </Drawer>
  );
};
