import { Icon } from "@chakra-ui/react";

const Logo = ({ height = 28, ...props }: { height?: number }) => {
  // const [isTablet] = useMediaQuery(`(min-width: ${breaks.lg})`);
  // const height = !isTablet ? "22px" : "28px";

  return (
    <Icon
      viewBox="0 0 200 170"
      height={`${height}px`}
      width={`${height} * 0.85px`}
      {...props}
    >
      <path
        fill="currentColor"
        d="M112.73,124a19.18,19.18,0,0,0-8.5,2,45.59,45.59,0,1,1,0-81.88,19.27,19.27,0,0,0,17-34.57A84.13,84.13,0,1,0,84.12,169.12a83.28,83.28,0,0,0,37.12-8.62A19.26,19.26,0,0,0,112.73,124Zm63.82-5.37A23.46,23.46,0,1,0,200,142,23.46,23.46,0,0,0,176.55,118.58Z"
      />
    </Icon>
  );
};

export default Logo;
