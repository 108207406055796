import Link from "next/link";
import {
  Flex,
  Box,
  HStack,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import Logo from "@/components/Logo";
import { NavbarAvatar } from "@/components/Nav";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { MdCallMade } from "react-icons/md";
// import { MdCallMade, MdClose } from "react-icons/md";
// import { ProfileIcon } from "@/components/Icons";
import config from "@/config";
import { mainW, asideW, asideBg } from "@/theme/layerStyles";

interface INavbarTop {
  // onLogout: () => void;
  signedIn?: boolean;
  showLogoCentered?: boolean;
  showAsideBg?: boolean;
  isMobileMenuOpen?: boolean;
  onMobileMenuToggle?: () => void;
}

export const NavbarTop = ({
  signedIn,
  showLogoCentered,
  showAsideBg,
  isMobileMenuOpen,
  onMobileMenuToggle,
}: INavbarTop) => {
  const theme = useTheme();
  // console.log(theme);
  const [isDesktop] = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);

  return (
    <Box
      pos={{ lg: "fixed" }}
      w="full"
      zIndex={99}
      borderBottom="1px"
      borderColor="blackAlpha.100"
    >
      <Flex
        as="nav"
        pos="relative"
        w="full"
        maxW={mainW}
        mx="auto"
        bg="white"
        layerStyle="navMinH"
        justifyContent="space-between"
        alignItems="center"
      >
        {/* LOGO */}
        <Flex
          h="full"
          px="insetLg"
          align="center"
          bg={showAsideBg ? { lg: asideBg } : "white"}
          w={showLogoCentered ? "full" : { lg: asideW }}
          justifyContent={showLogoCentered ? "center" : "flex-start"}
        >
          <Link href="/" passHref>
            <Button
              variant="ghost"
              pl={showLogoCentered ? 1 : 0}
              pr={0}
              _hover={{ bg: "none" }}
            >
              <Logo />
            </Button>
          </Link>
        </Flex>

        {/* MOBILE NAV */}
        {!isDesktop && signedIn && onMobileMenuToggle && (
          <Flex
            position="absolute"
            right={4}
            justify="flex-start"
            align="center"
          >
            <IconButton
              variant="navLink"
              color="textblack"
              aria-label="open navigation"
              onClick={onMobileMenuToggle}
              icon={
                isMobileMenuOpen ? (
                  <CloseIcon boxSize="16px" />
                ) : (
                  <HamburgerIcon boxSize="24px" />
                )
              }
            />
            {/* <Button
            variant="navLink"
            color="black"
            _hover={{ textDecoration: "none" }}
            onClick={onMobileMenuToggle}
          >
            {isMobileMenuOpen ? "Close" : "Menu"}
          </Button> */}
          </Flex>
        )}

        {/* DESKTOP NAV */}
        {isDesktop && (
          <HStack
            position="absolute"
            right="0"
            pr="insetLg"
            align="center"
            spacing="2"
          >
            <Button
              as="a"
              href={config.marketingSiteUrl}
              rel="noopener noreferrer"
              target="_blank"
              variant="capsule"
              color="blackAlpha.500"
              bg="transparent"
              border="1px solid"
              borderColor="blackAlpha.300"
              fontSize="13px"
              maxH="29px"
              px="w2"
              pt="2px"
              pr="w1"
              _hover={{
                bg: "uranianblue.100",
                color: "uranianblue.900",
                borderColor: "uranianblue.900",
              }}
              // maxW="75px"
              style={{ textDecoration: "none" }}
              rightIcon={<MdCallMade />}
              sx={{
                " & .chakra-button__icon": {
                  ml: "1px",
                  mt: "-0.5px",
                },
                " & .chakra-button__icon svg": {
                  height: "0.9em",
                  width: "0.9em",
                },
              }}
            >
              Shop
            </Button>
            {/* <Button
            as="a"
            href={config.marketingSiteUrl}
            rel="noopener noreferrer"
            variant="navLink"
            sx={{
              "& span": {
                margin: "0",
              },
            }}
            px="0"
            maxH="32px"
            minW="27px"
            rightIcon={<BagIcon width="27px" height="32px" />}
          /> */}

            {/* box <Menu> b/c: https://github.com/chakra-ui/chakra-ui/issues/3173#issuecomment-981403435 */}
            {signedIn && (
              <Box>
                <NavbarAvatar />
              </Box>
            )}
          </HStack>
        )}
      </Flex>
    </Box>
  );
};
