import { Flex, FlexProps } from "@chakra-ui/react";

/* NavsideShell set the bg color using the `asideShell` layerStyle. It is the defining style of the split nav / main content layout */

export const NavsideShell = ({ children, ...props }: FlexProps) => {
  return (
    <Flex
      as="nav"
      zIndex={1}
      position="relative"
      display={{ base: "none", lg: "flex" }}
      direction="column"
      flexShrink={0}
      // flexGrow={1}
      // align="center"
      // justify="space-between"
      // minW="220px"
      // maxW="220px"
      // minH="100vh"
      // h="full"
      // p={{ base: 3, lg: 6 }}
      // pr="0 !important"
      layerStyle="asideShell"
      {...props}
      // _before={{
      //   // eslint-disable-next-line quotes
      //   content: `""`,
      //   bgGradient: "linear(to-r, transparent 0%, rgba(0,0,0,0.02) 100%)",
      //   pos: "absolute",
      //   top: "0",
      //   bottom: "0",
      //   right: "0",
      //   width: 8,
      // }}
    >
      {children}
    </Flex>
  );
};
