import * as React from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useWindowSize } from "usehooks-ts";

/*
  LayoutMain wraps all content in DefaultLayout and provides a window height measurement, which is crucial for the scoring layout. It also wraps the content in Framer's motion system for page transitions.
 */

interface ILayoutMain extends FlexProps {
  showCentered?: boolean;
}

export const LayoutMain = ({ showCentered = false, children }: ILayoutMain) => {
  // may need a debounce?
  // https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
  // https://usehooks-typescript.com/react-hook/use-debounce
  // FYI, we are using `use-debounce` in the useSearch hook
  const { height } = useWindowSize();

  // DO NOT DELETE: please leave as an example of use
  // const heightStyle = {
  // "--screen-height": `${height}px`,
  // } as React.CSSProperties;

  React.useEffect(() => {
    if (height) {
      // example for adding a class…
      // document.body.classList.add("bg-gray");
      // return () => document.body.classList.remove("bg-gray");
      document.documentElement.style.setProperty(
        "--screen-height",
        `${height}px`
      );
      return () =>
        document.documentElement.style.removeProperty("--screen-height");
    }
    return () => {};
  }, [height]);

  const insetStyle = { base: 0, lg: "insetLg" };

  return (
    <Flex
      as="main"
      pos="relative"
      overflow="auto"
      direction="column"
      placeItems="stretch"
      flexGrow={1}
      bg="white"
      className="LayoutMain all-screen-h"
      pl={showCentered ? insetStyle : 0}
      // set pr here so we can easily use bg colour on the `Navside` component
      pr={insetStyle}
      // ensure motion.div fills the viewport
      sx={{
        "& > div": {
          h: "full",
        },
      }}
    >
      {/* <div>
        The current window dimensions are:{" "}
        <code>{JSON.stringify({ width, height })}</code>
      </div> */}
      {/* https://www.framer.com/docs/animate-presence/#props */}
      <AnimatePresence mode="wait">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </Flex>
  );
};
