import { Box, Flex, FlexProps } from "@chakra-ui/react";
import { mainW } from "@/theme/layerStyles";

/* LayoutRoot wraps `Navside` and `LayoutMain` in a flexbox to enable a binary layout. It also adds a maxWidth with a background gradient. */

interface ILayoutRoot extends FlexProps {
  showAsideBg?: boolean;
}

export const LayoutRoot = ({
  showAsideBg,
  children,
  ...props
}: ILayoutRoot) => {
  return (
    <Box
      // don't set height, use `all-screen-h`
      className="ReactRootFirstChild LayoutRoot all-screen-h"
      w="full"
      minH="full"
      bg="white"
      bgGradient={
        showAsideBg
          ? { lg: "linear(to-r, grey.10 0%, grey.10 50%, white 50%)" }
          : "initial"
      }
    >
      <Flex
        direction={{ base: "column", lg: "row" }}
        alignItems="stretch"
        placeItems="stretch"
        w="full"
        maxW={showAsideBg ? mainW : "auto"}
        mx="auto"
        h="full"
        minH="full"
        overflow="hidden"
        {...props}
      >
        {children}
      </Flex>
    </Box>
  );
};
