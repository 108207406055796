import * as React from "react";
import { Box } from "@chakra-ui/react";

export interface LayoutBaseProps {
  children: React.ReactNode;
  // bgGray?: boolean;
}

export default function LayoutBase({ children }: LayoutBaseProps) {
  return (
    <Box
      // bg={bgGray ? "grey.10" : "transparent"}
      overflow="hidden"
      minH="100vh"
      // pb="w9"
    >
      {children}
    </Box>
  );
}
