import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useSelector, useDispatch } from "react-redux";
import { useDisclosure, useMediaQuery, useTheme } from "@chakra-ui/react";
import LayoutBase from "@/components/Layout/LayoutBase";
import { LayoutRoot, LayoutMain } from "@/components/Layout";
// import { BlockLoading } from "@/components/Block";
// import BarResendEmailVerification from "@/components/BarResendEmailVerification";
// import BarEmailVerified from "@/components/BarEmailVerified";
import { NavbarTop, Navside, NavsideDrawer } from "@/components/Nav";
// import NavbarTop from "@/components/Nav/NavbarTop";
import * as authActions from "@/store/authentication/actions";
import encodeQueryString from "@/lib/encodeQueryString";
import ErrorPage from "next/error";

export const sidebarLinks = [
  {
    name: "To-Dos",
    href: "/",
    exact: true,
  },
  {
    name: "Allergy Tests",
    href: "/allergy-test-kit",
  },
  {
    name: "Orders",
    href: "/orders",
  },
];

export const accountLinks = [
  {
    name: "Profile",
    href: "/profile",
  },
  {
    name: "Billing",
    href: "/billing",
  },
];

export interface ProtectedLayoutProps {
  children: React.ReactNode;
  noSidebar?: boolean;
  showCentered?: boolean;
  // Make the page only accessable by certain accounts
  allowedEmails?: Array<string>;
  showAsideBg?: boolean;
}

const ProtectedLayout = ({
  noSidebar = false,
  showCentered = false,
  showAsideBg = true,
  allowedEmails,
  children,
}: ProtectedLayoutProps) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const theme = useTheme();
  const [isDesktop] = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`);
  const user = useSelector(authActions.selectors.getUser);
  // console.log("user", user);
  // const fromQuery = router.query.from;

  useEffect(() => {
    const fetchUser = async () => {
      if (!user) {
        const userResponse: any = await dispatch(authActions.fetchUser());

        if (userResponse.error) {
          router.push(`/login?origin=${encodeQueryString(router.asPath)}`);
        }
      }
    };

    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Stops the page flashing before redirecting if the user is not signed in
  // and allows the jsx to assume user exists
  if (!user) return null;

  if (allowedEmails && !allowedEmails.includes(user.email)) {
    return <ErrorPage statusCode={404} />;
  }

  return (
    <LayoutBase>
      <NavbarTop
        signedIn
        showAsideBg={showAsideBg}
        showLogoCentered={showCentered}
        isMobileMenuOpen={isOpen}
        onMobileMenuToggle={onToggle}
      />

      {/* TODO: move email verify to Profile */}
      {/* {!user.isEmailVerified && (
          <BarResendEmailVerification email={user.email} />
        )}
        {fromQuery === "email-verified" && <BarEmailVerified />} */}

      <LayoutRoot as="main" pos="relative" showAsideBg={showAsideBg}>
        {/* NAV */}
        {isDesktop && !noSidebar ? (
          <Navside links={sidebarLinks} />
        ) : (
          <NavsideDrawer
            links={[...sidebarLinks, ...accountLinks]}
            isOpen={isOpen}
            onMobileMenuClose={onClose}
          />
        )}

        {/* MAIN */}
        <LayoutMain showCentered={showCentered}>
          {/* <BlockLoading maxH={{ base: "90vh", xl: "40vh" }} /> */}

          {/* alert? */}
          {/* <LayoutAlert user={this.props.user} /> */}

          {/* CHILDREN */}
          {/* <Component
            {...this.props.matchProps}
            user={this.props.user}
          /> */}
          {children}
        </LayoutMain>
      </LayoutRoot>
    </LayoutBase>
  );
};

export default React.memo(ProtectedLayout);
