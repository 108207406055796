import { IUser } from "@/types";

export function firstName(user: IUser) {
  if (!user) return null;
  return user.isChildAccount ? user.caregiverFirstName : user.firstName;
}

export function fullName(user: IUser) {
  if (!user) return null;
  return user.isChildAccount
    ? `${user.caregiverFirstName} ${user.caregiverLastName}`
    : `${user.firstName} ${user.lastName}`;
}
