import { Icon } from "@chakra-ui/react";

const Logo = ({ height = 28, ...props }: { height?: number }) => {
  // const [isTablet] = useMediaQuery(`(min-width: ${breaks.lg})`);
  // const height = !isTablet ? "22px" : "28px";

  return (
    <Icon
      viewBox="0 0 340 80"
      height={`${height}px`}
      width={`${height} * 4.25px`}
      {...props}
    >
      <defs>
        <linearGradient id="gradient">
          <stop offset="0%" stopColor="#F4C2B3" />
          <stop offset="100%" stopColor="#E5E0DC" />
        </linearGradient>
      </defs>
      <path
        fill="url(#gradient)"
        d="M53.78,0A5.87,5.87,0,0,0,47.9,5.87V72.93a5.88,5.88,0,0,0,11.76,0V5.87A5.88,5.88,0,0,0,53.78,0ZM238.2,28.71A25.69,25.69,0,0,0,212.91,50.1a5.2,5.2,0,0,0-.14.88,26.74,26.74,0,0,0-.22,3.37,25.64,25.64,0,0,0,44.87,17,5.88,5.88,0,0,0-8.81-7.79,13.9,13.9,0,0,1-24-6.25h33.13a5.87,5.87,0,0,0,5.81-6.74A25.76,25.76,0,0,0,238.2,28.71ZM227.46,45.54a13.87,13.87,0,0,1,21.46,0ZM34.35,66.23a5.75,5.75,0,0,0-2.59.61,13.9,13.9,0,1,1,0-25A5.87,5.87,0,1,0,37,31.34a25.41,25.41,0,0,0-11.31-2.63A25.65,25.65,0,1,0,37,77.38a5.88,5.88,0,0,0-2.61-11.15ZM93.56,28.71A25.68,25.68,0,0,0,68.27,50.1a6.29,6.29,0,0,0-.13.87,25,25,0,0,0-.22,3.38,25.64,25.64,0,0,0,44.87,17A5.88,5.88,0,0,0,104,63.52a13.9,13.9,0,0,1-24-6.25h33.14a5.91,5.91,0,0,0,4.45-2,5.81,5.81,0,0,0,1.36-4.7A25.79,25.79,0,0,0,93.56,28.71ZM82.81,45.54a13.88,13.88,0,0,1,21.47,0Zm248.34,20a7.16,7.16,0,1,0,7.16,7.15A7.15,7.15,0,0,0,331.15,65.55ZM312.22,0a5.87,5.87,0,0,0-5.87,5.87V32.81a25.64,25.64,0,1,0,11.74,21.56V5.87h0A5.87,5.87,0,0,0,312.22,0Zm-5.88,54.36a13.9,13.9,0,1,1-13.89-13.9,13.9,13.9,0,0,1,13.89,13.9ZM206.81,28.71A25.56,25.56,0,0,0,192.68,33a5.88,5.88,0,0,0-11.53,1.62V74.12a5.88,5.88,0,1,0,11.75,0V54.63c0-.09,0-.18,0-.27a13.91,13.91,0,0,1,13.9-13.9,5.88,5.88,0,1,0,0-11.75Zm-59.21,0a25.65,25.65,0,0,0-19.23,8.68,5.87,5.87,0,0,0,8.81,7.76,13.9,13.9,0,0,1,24,6.25H128.05a5.87,5.87,0,0,0-5.8,6.74,25.64,25.64,0,0,0,50.65.45,8.1,8.1,0,0,0,.13-.88,26.74,26.74,0,0,0,.22-3.37A25.69,25.69,0,0,0,147.6,28.71Zm0,39.54a14,14,0,0,1-10.72-5.08h21.46A13.88,13.88,0,0,1,147.6,68.25Z"
      />
    </Icon>
  );
};

export default Logo;
