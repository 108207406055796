import {
  Menu,
  MenuButton,
  MenuList,
  Button,
  Icon,
  Avatar,
} from "@chakra-ui/react";
import { MdAccountCircle } from "react-icons/md";
import { useSelector } from "react-redux";
import { NavbarAvatarMenu } from "@/components/Nav";
import { fullName } from "@/lib/name";
import * as authActions from "@/store/authentication/actions";

export const NavbarAvatar = () => {
  const user = useSelector(authActions.selectors.getUser);
  const name = fullName(user);
  const email = user ? user.email : null;

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="ghost"
        px={0}
        bg="transparent"
        justifyContent="flex-end"
        _focus={{ outline: "none" }}
        _hover={{ bg: "transparent" }}
        _active={{ bg: "transparent" }}
        // rightIcon={<Icon as={MdAccountCircle} boxSize="1.25em" />}
        sx={{
          "& span": {
            margin: "0",
          },
        }}
      >
        <Avatar
          size="sm"
          // name={name}
          // src="https://github.com/callumflack.png"
          // https://chakra-ui.com/docs/media-and-icons/avatar#customize-the-fallback-avatar
          icon={<Icon color="#1a202c" as={MdAccountCircle} fontSize="2.75em" />}
          // bg="blackAlpha.200"
          bg="transparent"
        />
      </MenuButton>
      <MenuList boxShadow="lg">
        <NavbarAvatarMenu name={name} email={email} />
      </MenuList>
    </Menu>
  );
};
