import { Flex, FlexProps } from "@chakra-ui/react";

export interface INavListText extends FlexProps {
  isActive?: boolean;
  isReduced?: boolean;
}

export const NavListTextH = { base: "32px", md: "36px" };

export const NavListText = ({
  as = "a",
  isActive,
  isReduced,
  children,
}: INavListText) => {
  const activeColor = "black";
  const color = isReduced ? "blackAlpha.600" : activeColor;

  return (
    <Flex
      as={as}
      width="full"
      alignItems="center"
      justifyContent="flex-start"
      // set fontFamily to use as child within a Button
      fontFamily="body"
      textStyle="textMd"
      fontWeight={isActive ? "600" : "400"}
      fontSize={{ base: "14px", xl: "15px" }}
      h={NavListTextH}
      px="w2"
      color={isActive ? activeColor : color}
      transition="background-color 500ms cubic-bezier(0.4, 0, 0.2, 1)"
      borderLeft="2px solid"
      borderColor={
        isActive ? { base: "transparent", lg: activeColor } : "transparent"
      }
      // textDecoration="none"
      _hover={{
        color: activeColor,
        textDecoration: "none",
      }}
      _focus={{
        color: activeColor,
        boxShadow: "initial",
        outline: "none",
      }}
      _active={{
        textDecoration: "none",
      }}
      // alt bar style
      // borderRadius="md"
      // bg={active ? "uranianblue.500" : "transparent"}
      // _hover={active ? { bg: "uranianblue.500" } : { bg: "uranianblue.200" }}
      // _selected={{ bg: "uranianblue.500" }}
    >
      {children}
    </Flex>
  );
};
