/**
 * Encodes the query string of a URI
 * Without encoding if the path includes an "&" in it's query params, the remaining query params won't be preserved
 *
 * For example see how the query parameters are seperated in the below url
 * URL: /login?origin=/register?quizSlug=v1&submissionId=d84c75e1-8c19-4f1c-b40b-e0f41ef23162
 * Query params:
 * - origin=/register?quizSlug=v1
 * - submissionId=d84c75e1-8c19-4f1c-b40b-e0f41ef23162
 */
export default function encodeQueryString(path: string): string {
  const [base, queryParams] = path.split("?");
  if (!queryParams) {
    // no query string
    return path;
  } else {
    return `${base}?${encodeURIComponent(queryParams)}`;
  }
}
