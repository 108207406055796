import * as React from "react";
import { VStack, StackProps } from "@chakra-ui/react";

export default function DefaultVStack({
  children,
  spacing = "w4",
  ...props
}: StackProps) {
  return (
    <VStack
      className="DefaultVStack"
      alignItems="flex-start"
      spacing={spacing}
      {...props}
      sx={{
        "& > *": {
          w: "full",
        },
      }}
    >
      {children}
    </VStack>
  );
}
