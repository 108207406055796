import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { Button, ButtonProps } from "@chakra-ui/react";
import * as authActions from "@/store/authentication/actions";
import * as fbpx from "@/lib/fbpx";

export const NavsideLogout = (props: ButtonProps) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const logout = async () => {
    await dispatch(authActions.logoutUser());
    await router.push("/login");
    fbpx.event({
      action: "Logout",
      name: "Logout",
      category: "Logout",
    });
  };

  return (
    <Button
      id="logout"
      variant="unstyled"
      justifyContent="flex-start"
      // match NavListText
      h={{ base: "40px", md: "36px" }}
      w="full"
      onClick={logout}
    >
      {props.children}
    </Button>
  );
};
