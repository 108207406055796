/* eslint-disable import/prefer-default-export */
// https://github.com/zsajjad/react-facebook-pixel/issues/53#issuecomment-699531480

// possibly set pageView here instead of using AnalyticsFacebookPixel component like we do with gtag?
// export const pageView = async ({ url }) => {
//   const { default: ReactPixel } = await import("react-facebook-pixel");
//   ReactPixel.pageView(url);
// };

// Track custom events
export const event = async ({ action, name, category }) => {
  const { default: ReactPixel } = await import("react-facebook-pixel");
  ReactPixel.trackCustom(action, {
    content_name: name,
    content_category: category,
  });
};

// Track standard events
// https://www.facebook.com/business/help/606577526529702?id=1205376682832142
export const fbq = async (...args) => {
  const { default: ReactPixel } = await import("react-facebook-pixel");
  ReactPixel.fbq(args);
};
