import { Box, BoxProps, Text, Divider } from "@chakra-ui/react";
import { NavList, NavListText, NavsideLogout } from "@/components/Nav";
import DefaultVStack from "@/components/VStack";
import { accountLinks } from "@/components/Layout/LayoutProtected";

interface INavbarAvatar extends BoxProps {
  name: string;
  email: string;
}

export const NavbarAvatarMenu = ({ name, email }: INavbarAvatar) => {
  return (
    <Box>
      <DefaultVStack spacing="2px" w="full" px="w2" pb="4px" pt="6px">
        {/* kinda matches NavListText but customised, so easier to duplicate rather than complicate existing components */}
        <Text
          as="div"
          textStyle="textMd"
          fontWeight={500}
          fontSize={{ base: "14px", xl: "15px" }}
        >
          {name}
        </Text>
        <Text as="div" textStyle="textSm" color="blackAlpha.600">
          {email}
        </Text>
      </DefaultVStack>
      <Box py={2}>
        <Divider />
      </Box>
      <NavList links={accountLinks} spacing={1} w="full">
        <Box py="2px">
          <Divider />
        </Box>
        <NavsideLogout>
          <NavListText as="div">Log out</NavListText>
        </NavsideLogout>
      </NavList>
    </Box>
  );
};
