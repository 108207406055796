import { StackProps } from "@chakra-ui/react";
import { NavListItem } from "@/components/Nav";
import DefaultVStack from "@/components/VStack";
import { INavLink } from "./Nav.type";

interface INavside extends StackProps {
  links: INavLink[];
}

export const NavList = ({ links, children, ...props }: INavside) => {
  return (
    <DefaultVStack {...props}>
      {links.map((link) => (
        <NavListItem key={link.href} link={link} />
      ))}

      {/* optional: for the logout button */}
      {children}
    </DefaultVStack>
  );
};
